import React from "react"
import Img from "gatsby-image"

import Hero from "layout/Hero"
import Container from "layout/Container"
import useHomeImages from "./hooks/useHomeImages"

const MedGrocer = () => {
  const data = useHomeImages()
  const medgrocerLogo = data.medgrocerLogo.childImageSharp.fixed
  const medgrocerWebsite = data.medgrocerWebsite.childImageSharp.fluid
  console.log(data)
  return (
    <Hero size="small">
      <Container desktop={12} fullhd={10} isCentered>
        <div className="columns">
          <div className="column">
            <Img fluid={medgrocerWebsite} />
          </div>
          <div className="content column is-align-self-center">
            <Img fixed={medgrocerLogo} className="mt-2 mb-2" />
            <p>
              MedGrocer's technology platforms optimize "medicine-as-a-service"
              for patients and companies. Its ePharmacy, corporate health
              services, and patient programs empower customers to get their
              medicines, vaccines, and medical services conveniently,
              cost-effectively, and intelligently.
            </p>
            <p>
              MedGrocer's team of over 300 people serve 700k patients and 300
              institutions directly and through partnerships with pharma
              companies.
            </p>

            <a
              className="button is-teal"
              href="https://medgrocer.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Visit MedGrocer.com
            </a>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default MedGrocer
