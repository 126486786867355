import React from "react"

import Layout from "layout"
import HomeBanner from "components/StaticPages/Home/HomeBanner"
import MedGrocer from "components/StaticPages/Home/MedGrocer"

const IndexPage = () => {
  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Lifologic"
    >
      <HomeBanner />
      <MedGrocer />
    </Layout>
  )
}

export default IndexPage
