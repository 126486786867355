import React, { useEffect } from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import { animate } from "services/animations"
import useHomeImages from "./hooks/useHomeImages"

const HomeBanner = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      target: ".fade-from-left",
      duration: 1,
    })
  }, [])

  const data = useHomeImages()
  const banner = data.banner.childImageSharp.fluid.src

  return (
    <Hero
      size="medium"
      background={{
        image: banner,
        position: "center",
      }}
    >
      <Container
        desktop={12}
        fullhd={10}
        customClassName="fade-from-left"
        isCentered
      >
        <div className="columns">
          <div className="column content is-8-tablet is-8-desktop is-6-fullhd mt-2-mobile">
            <p className="title is-size-1-desktop is-size-3-mobile is-size-2-tablet has-text-primary has-text-left">
              Better Healthcare
            </p>

            <p className="subtitle is-size-3-desktop is-size-5-mobile is-size-4-tablet">
              Lifologic invests in and empowers ventures that are defining and
              shaping the healthcare ecosystem.
            </p>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
